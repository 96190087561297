import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import BettingDialog from '../dialogs/BettingDialog';
import LoadingDialog from '../dialogs/LoadingDialog';
import ToastDialog from '../dialogs/ToastDialog';
import WinDialog from '../dialogs/WinDialog';
import RuleDialog from '../dialogs/WheelocityRuleDialog';
import '../../MainStyle.css';
import { setStorage, getStorage } from '../modals/Storage';
import { API_ACCESS_URL } from '../modals/Constants';

function Wheelocity(){

    const [remainingSec, setCount] = useState(60);
    const [gamePlayTime, setGamePlayTime] = useState(0);
    const [gamewaitingTime, setWaitingTime] = useState(0);
    const [rotateValues, setRotateValue] = useState(0);
    const degVal = [3652, 3956, 3899, 3757, 3918, 3700, 3671, 3813, 3776, 3737, 3633, 3832, 3794, 3880, 3852,
      3937, 3718, 3615, 3662, 3947, 3728, 3681, 3747, 3624, 3708, 3766, 3823, 3861, 3605, 3785, 3642, 3928, 3804,
      3890, 3908, 3842];

    const greenValues = [29, 20, 32, 35, 34, 28, 36, 27, 33, 30, 26, 23, 21, 25, 22, 19, 31, 24];
    const yellowValues = [2, 16, 5, 3, 14, 15, 12, 8, 13, 9, 4, 10, 17, 6, 7, 1, 11, 18];

    const [pageConst, setGameData] = useState({
        pageTitle: "Roulette",
        gamePeriodId: "",
        gameCode: "WHEELOCITY",
        gameFolderName: "wheelocity",
        showCircleAnim: false,
        isControlEnable: true,
        requestAccountData: true,
        isLoadingShow: false,
        ruleDialogShow: false,
        toastDialogShow: false,
        toastMessage: "",
        tabActiveReord: "everyoneorder",
        tabActiveNumber: "1-10",
        gameWinDetails: [],
        gameRecordsList: [],
        mygameRecordsList: [],
        everyOnesOrderList: [],
    });

    const [winDialogConst, setWinDialogConst] = useState({
      winDialogShow: false,
      constPeriodId: "",
      constWinAmount: 0,
    });

    const [bettingDialogConst, setBettingDialogConst] = useState({
      bettingDialogShow: false,
      bettingDialogTitle: "Join Green",
      bettingDialogTheme: "dlg-thm-green",
      gameBetRoutePath: "route-bet-common",
      gameSelectedBet: "",
    });

    const handleVisibilityChange = useCallback(() => {
      let isVisible = document.visibilityState === 'visible';
      if(isVisible){
        getGameData(false);
      }
    }, []);

    const getColorName = (data) =>{
      let returnVal = "";

      if(greenValues.includes(Number(data))){
        returnVal = "green";
      }else if(yellowValues.includes(Number(data))){
        returnVal = "yellow";
      }else{
        returnVal = "red";
      }

      return returnVal;
    }

    const getAnimalName = (data) =>{
      let returnVal = "";

      if(data=="17" || data=="25" || data=="6" || data=="00" || data=="22" || data=="7" 
      || data=="19" || data=="1" || data=="31" || data=="11" || data=="24" || data=="18"){
        returnVal = "elephant";
      }else if(data=="20" || data=="16" || data=="32" || data=="5" || data=="35" || data=="3" 
      || data=="34" || data=="14" || data=="0" || data=="28" || data=="15" || data=="36"){
        returnVal = "tiger";
      }else if(data=="29" || data=="2"){
        returnVal = "crown";
      }else{
        returnVal = "cow";
      }

      return returnVal;
    }

    const updateActiveTabNumber = (data) =>{
      setGameData(previousState => {
        return { ...previousState, tabActiveNumber: data }
      });
    }

    const updatePeriod = (data) => {
        setGameData(previousState => {
          return { ...previousState, gamePeriodId: data[0].game_period_id }
        });
    }
    
    const updateWinDialogState = (data) => {
      setWinDialogConst(previousState => {
        return { ...previousState, winDialogShow: data }
      });
    }

    const updateGameControl = (data) => {
        setGameData(previousState => {
            return { ...previousState, isControlEnable: data }
        });
    }

    const updateReqAcntData = (data) => {
      setGameData(previousState => {
        return { ...previousState, requestAccountData: data }
    });
    }

    const updateActiveTab = (data) => {
        setGameData(previousState => {
            return { ...previousState, tabActiveReord: data }
        });
    }

    const updateLoadingStatus = (data) => {
      setGameData(previousState => {
          return { ...previousState, isLoadingShow: data }
      });
    }

    const getDialogThemeClassName = (data) =>{
      let returnVal = "dlg-thm-orange";
      if(data=="A"){
        returnVal = "dlg-thm-violet";
      }else if(data=="B"){
        returnVal = "dlg-thm-red";
      }

      return returnVal;
    }
    
    const updateBettingDialogState = (e,data,selected,title) => {
      if(selected!=""){
        setBettingDialogConst(previousState => {
          return { ...previousState, gameSelectedBet: selected }
        });
      }

      if(e!=null && e.target.className=="ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog"){
        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogShow: data }
        });
      }else if(data==true && pageConst.isControlEnable){
        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogShow: data }
        });

        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogTitle: title }
        });

        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogTheme: getDialogThemeClassName(selected) }
        });
        
      }else if(data=="dismiss"){
        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogShow: false }
        });
      }
    }

    const topBarClickAction = (data) =>{
      if(data=="multiBtn1"){

      }else{
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: true }
        });
      }
    }

    const updateGameRuleDialog = (e,data) => {
      if(e!=null && e.target.className=="ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg activeDialog"){
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: data }
        });
      }else if(data=="dismiss"){
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: false }
        });
      }else if(data=="true"){
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: true }
        });
      }
    } 

    const updateToastDialogState = (data,msg) => {

      setGameData(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setGameData(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const getCapitalLetter = (data) => {
       let returnVal = "";

       if(data=="red"){
         returnVal = "R";
       }else if(data=="green"){
         returnVal = "G";
       }else if(data=="violet"){
         returnVal = "V";
       }else if(data=="yellow"){
        returnVal = "Y";
       }else if(data=="tiger"){
        returnVal = "T";
       }else{
         returnVal = data;
       }

       return returnVal;  
    }

    const getBetBgClass = (data) => {
        let returnVal = "";

        if(data=="R"){
            returnVal = "bg-red";
        }else if(data=="G"){
            returnVal = "bg-green";
        }else if(data=="Y"){
          returnVal = "bg-yellow";
        }else{
            returnVal = "bg-black";
        }

        return returnVal;
    }

    const updateGameRecords = (data,resetStatus) => {
        let tempData = [],currentPeriod="",numNeedToRemove="";
        for (let i = 0; i < data.length; i++) {
          if(i==0){

            if(resetStatus){            
              showCircleAnim(degVal[data[i]['match_result']-1]);
            }

            currentPeriod = Number(data[i]['period_id'].slice(-3))+1;
            if(currentPeriod < 10){
              currentPeriod = "00"+currentPeriod;
            }else if(currentPeriod < 100){
              currentPeriod = "0"+currentPeriod;
            }
            numNeedToRemove = 10-Number(data[i]['period_id'].slice(-1));
          }
  
          tempData.push(
            <div key={i}>
                <div className={`col-view v-center pd-3 ft-sz-14 br-5 cl-white ${getColorName(data[i]['match_result'])=='red' ? 'bg-red' : getColorName(data[i]['match_result'])=='green' ? 'bg-green' : 'bg-yellow'}`}>{data[i]['match_result']}</div>

                <div className='col-view v-center pd-3 mg-t-5 ovf-hidden br-5 bg-white'>
                  <img className='h-15-p' src={require(`../icons/CIRCLE/${getAnimalName(data[i]['match_result'])=='cow' ? '3.png' : getAnimalName(data[i]['match_result'])=='tiger' ? '4.png' : getAnimalName(data[i]['match_result'])=='elephant' ? '5.png' : '6.png'}`)} alt="icon" />
                </div>
            </div>)
        };

        setGameData(previousState => {
          return { ...previousState, gameRecordsList: tempData }
        });

    }

    const updateEveryOnesOrder = (data) => {
      setGameData(previousState => {
        return { ...previousState, everyOnesOrderList: data }
      });
    }

    const getFixedDecimalVal = (data) => {
      return Number(data).toFixed();
    }

    function shuffle(array) {
      let currentIndex = array.length,  randomIndex;
    
      // While there remain elements to shuffle.
      while (currentIndex > 0) {
    
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
    
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
    
      return array;
    }

    const recentBetDetails = (invested_amount) =>{
      setStorage("recentcirclebet", pageConst.gamePeriodId);

      let tempData = [];

      tempData.push(
        <div key={60} className="row-view mg-t-15 sb-view">
          <span className='ft-sz-14'>{pageConst.gamePeriodId}</span>
          <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getBetBgClass(bettingDialogConst.gameSelectedBet)}`}>{getCapitalLetter(bettingDialogConst.gameSelectedBet)}</span>
          <span className='ft-sz-15'>{invested_amount}.00</span>
          <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center`}></span>
          <span className={`w-65-p ft-sz-15 txt-a-end`} ></span>
        </div>)
        
        let finalArr = [tempData].concat(pageConst.mygameRecordsList);
        
        setGameData(previousState => {
          return { ...previousState, mygameRecordsList: finalArr }
        });
    }

    const updateMyGameRecords = (data) => {
      let tempData = [];

        for (let i = 0; i < data.length; i++) {

          if(i==0 && getStorage("recentcirclebet")==data[0]['m_period_id'] && data[0]['m_status']=="profit"){
            setWinDialogConst(previousState => {
              return { ...previousState, constPeriodId: data[0]['m_period_id'] }
            });

            setWinDialogConst(previousState => {
              return { ...previousState, constWinAmount: data[0]['m_profit'] }
            });

            setStorage("recentcirclebet", "");
            updateWinDialogState(true);
          }

          let tempMatchReslt = "";
          if(data[i]['m_status']=="profit"){
            if(Number(data[i]['m_profit']) >= 1000000){
              tempMatchReslt = "+₹"+getFixedDecimalVal(Number(data[i]['m_profit'])/1000)+"K";
            }else if(Number(data[i]['m_profit']) >= 100000){
              tempMatchReslt = "+₹"+getFixedDecimalVal(Number(data[i]['m_profit'])/1000)+"K";
            }else if(Number(data[i]['m_profit']) >= 10000){
              tempMatchReslt = "+₹"+getFixedDecimalVal(Number(data[i]['m_profit'])/1000)+"K";
            }else{
              tempMatchReslt = "+₹"+Number(data[i]['m_profit']);
            }
          }else if(data[i]['m_status']=="loss"){
            if(Number(data[i]['m_cost']) >= 1000000){
              tempMatchReslt = "-₹"+getFixedDecimalVal(Number(data[i]['m_cost'])/1000)+"K";
            }else if(Number(data[i]['m_cost']) >= 100000){
              tempMatchReslt = "-₹"+getFixedDecimalVal(Number(data[i]['m_cost'])/1000)+"K";
            }else if(Number(data[i]['m_cost']) >= 10000){
              tempMatchReslt = "-₹"+getFixedDecimalVal(Number(data[i]['m_cost'])/1000)+"K";
            }else{
              tempMatchReslt = "-₹"+Number(data[i]['m_cost']);
            }
          }
          
          tempData.push(
            <div key={i} className="row-view mg-t-15 sb-view">
              <span className='ft-sz-14'>{data[i].m_period_id}</span>
              <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getBetBgClass(data[i]['m_color'])}`}>{getCapitalLetter(data[i]['m_color'])}</span>
              <span className='ft-sz-15'>{data[i]['m_cost']+".00"}</span>
              <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getBetBgClass(data[i]['m_result'])}`}>{getCapitalLetter(data[i]['m_result'])}</span>
              <span className={`w-65-p ft-sz-15 txt-a-end ${data[i]['m_status']=="loss" ? 'cl-red' : 'cl-green'}`} >{tempMatchReslt}</span>
            </div>)
        };

        setGameData(previousState => {
            return { ...previousState, mygameRecordsList: tempData }
        });
    }

    const getRandBool = () => {
      let returnVal = false;

      let status=Math.round(Math.random())
      if(status==1){
        returnVal = true;
      }

      return returnVal;
    }

    const getRandomNum = (min, max, type) => {
      if(type=="multi"){
        return Math.round((Math.random()*(max-min)+min)/10)*10;
      }else{
        return Math.floor(Math.random() * (max - min) + min);
      }
    }

    const updateEveryOnesOrders = (periodId,remainSec,controlEnabled,everyoneorder) => {
      if(periodId!=""){

        let tempData = [];
        
        if(controlEnabled==true && getRandBool()){

          if(everyoneorder.length > 0){
            tempData = everyoneorder;
          }
  
          let randBetId = getRandomNum(100,999, '');
          let randBetAmnt = 10;

          if(getRandBool()){
            randBetAmnt = getRandomNum(10,300, 'multi');
          }else{
            randBetAmnt = getRandomNum(10,2000, 'multi');
          }

          let randBetNum = "";

          if(getRandBool()){
            if(getRandBool()){
              randBetNum = getRandomNum(0,36, '');
            }else if(getRandBool()){
              randBetNum = "T";
            }else if(getRandBool()){
              randBetNum = "E";
            }else{
              randBetNum = "C";
            }
          }else if(getRandBool()){
            randBetNum = "R";
          }else if(getRandBool()){
            randBetNum = "Y";
          }else{
            randBetNum = "G";
          }
  
          tempData.push(
            <div key={remainSec} className="row-view mg-t-15 sb-view">
              <span className='ft-sz-14'>{periodId}</span>
              <span className='ft-sz-14'>***{randBetId}</span>
              <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getBetBgClass(randBetNum)}`}>{getCapitalLetter(randBetNum)}</span>
              <span className="ft-sz-14 w-50-p">₹{randBetAmnt}</span>
            </div>)

          updateEveryOnesOrder(tempData);
        }
        
      }
    }

    function getGameData(resetStatus){
      updateLoadingStatus(true);

      const fecthApiData = async (url) => {
        
        try {
          const res = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Route': 'route-setup-common',
              'AuthToken': getStorage("secret"),
            }
          });

          const data = await res.json();

          updateLoadingStatus(false);

          if(data.status_code=="success"){
            updatePeriod(data.gamedata);
            setCount(data.gamedata[0].game_remain_seconds);
            setGamePlayTime(data.gamedata[0].game_play_time);
            setWaitingTime(data.gamedata[0].game_disable_time);
            updateGameRecords(data.matchrecords,resetStatus);
            updateMyGameRecords(data.mymatchrecords);
          }else{
            updateToastDialogState(true, "Something went wrong! Please try again or login!");
          }

        } catch (error) {
          updateLoadingStatus(false);
          console.log(error);
        }
      };

      fecthApiData(API_ACCESS_URL+"?USER_ID="+getStorage("uid")+"&PROJECT_NAME="+pageConst.gameCode);
    }

    function setUpTimer(resetStatus){
        if(pageConst.requestAccountData==true){
            updateReqAcntData(false);
            getGameData(resetStatus);
        }
    }


    const hideCircleAnim = () =>{
      setGameData(previousState => {
        return { ...previousState, showCircleAnim: false }
      });

      setRotateValue(0);
    }

    const showCircleAnim = (animDegree) =>{
      setGameData(previousState => {
        return { ...previousState, showCircleAnim: true }
      });

      if(animDegree!=""){
        setRotateValue(animDegree);
      }
    }

    const getRandomInt = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    useEffect(() => {

        document.addEventListener("visibilitychange", handleVisibilityChange);
        setUpTimer(false);

        const interval = setInterval(() => {
            if(remainingSec >= 0){
                setCount(remainingSec - 1);

                if(remainingSec <= 0){
                    getGameData(true);
                    setCount(60);
                    updateEveryOnesOrder([]);
                }else{

                    if(remainingSec < gamewaitingTime && pageConst.isControlEnable == true){
                      updateGameControl(false);
                    }else if (remainingSec > gamewaitingTime && remainingSec < gamePlayTime-12  && pageConst.isControlEnable == false) {
                      hideCircleAnim();
                      updateGameControl(true);
                    }

                    updateEveryOnesOrders(pageConst.gamePeriodId,remainingSec,pageConst.isControlEnable,pageConst.everyOnesOrderList.reverse());
                }
            }
        }, 1000);

        //Clearing the interval
        return () => {
          clearInterval(interval);
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        }

    }, [remainingSec]);

    return (
      <div className="v-center">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-circle">
           <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="Rule" updateState={topBarClickAction}/>
           <LoadingDialog intentData={pageConst}/>
           <WinDialog intentData={winDialogConst} updateState={updateWinDialogState}/>
           <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
           <RuleDialog intentData={pageConst} updateState={updateGameRuleDialog}/>
           <BettingDialog gameCode={pageConst.gameCode} dialogData={bettingDialogConst} updateState={updateBettingDialogState} toastUpdate={updateToastDialogState} recentBetDetails={recentBetDetails}/>

           <div className="game-manage-view col-view mg-t-45">

              <div className="col-view">

              <div className='row-view sb-view w-100 pd-5-10 mg-t-15'>

                {pageConst.gameRecordsList}

                {/* <div>
                  <div className='col-view v-center pd-3 ft-sz-14 br-5 bg-white'>16</div>

                  <div className='col-view v-center pd-3 mg-t-5 ovf-hidden br-5 bg-white'>
                    <img className='h-15-p' src={require(`../icons/CIRCLE/3.png`)} alt="icon" />
                  </div>
                </div>

                <div>
                  <div className='col-view v-center pd-3 ft-sz-14 br-5 bg-white'>16</div>

                  <div className='col-view v-center pd-3 mg-t-5 ovf-hidden br-5 bg-white'>
                    <img className='h-15-p' src={require(`../icons/CIRCLE/4.png`)} alt="icon" />
                  </div>
                </div>

                <div>
                  <div className='col-view v-center pd-3 ft-sz-14 br-5 bg-white'>16</div>

                  <div className='col-view v-center pd-3 mg-t-5 ovf-hidden br-5 bg-white'>
                    <img className='h-15-p' src={require(`../icons/CIRCLE/5.png`)} alt="icon" />
                  </div>
                </div>

                <div>
                  <div className='col-view v-center pd-3 ft-sz-14 br-5 bg-white'>16</div>

                  <div className='col-view v-center pd-3 mg-t-5 ovf-hidden br-5 bg-white'>
                    <img className='h-15-p' src={require(`../icons/CIRCLE/4.png`)} alt="icon" />
                  </div>
                </div>

                <div>
                  <div className='col-view v-center pd-3 ft-sz-14 br-5 bg-white'>16</div>

                  <div className='col-view v-center pd-3 mg-t-5 ovf-hidden br-5 bg-white'>
                    <img className='h-15-p' src={require(`../icons/CIRCLE/3.png`)} alt="icon" />
                  </div>
                </div>

                <div>
                  <div className='col-view v-center pd-3 ft-sz-14 br-5 bg-white'>16</div>

                  <div className='col-view v-center pd-3 mg-t-5 ovf-hidden br-5 bg-white'>
                    <img className='h-15-p' src={require(`../icons/CIRCLE/5.png`)} alt="icon" />
                  </div>
                </div>

                <div>
                  <div className='col-view v-center pd-3 ft-sz-14 br-5 bg-white'>16</div>

                  <div className='col-view v-center pd-3 mg-t-5 ovf-hidden br-5 bg-white'>
                    <img className='h-15-p' src={require(`../icons/CIRCLE/3.png`)} alt="icon" />
                  </div>
                </div>

                <div>
                  <div className='col-view v-center pd-3 ft-sz-14 br-5 bg-white'>16</div>

                  <div className='col-view v-center pd-3 mg-t-5 ovf-hidden br-5 bg-white'>
                    <img className='h-15-p' src={require(`../icons/CIRCLE/4.png`)} alt="icon" />
                  </div>
                </div>

                <div className='h-50-p pd-5 v-center br-7 bg-white'>
                  <img className='h-15-p' src='https://static.lotterybox.com/game/common/8.png' alt="icon" />
                </div> */}

               </div>

               <div className={`col-view a-center pd-15`}>

                <div className='w-100 col-view v-center ovf-hidden'>
                  <img className={`max-w-100 z-i-100`} style={{height: '60px'}} src={require(`../icons/CIRCLE/2.png`)} alt="icon" onClick={() => showCircleAnim()}/>

                  <div className="pr-v max-w-100" style={{marginTop: '-20px'}}>
                    <div className='h-w-80 ps-ab ps-tp-50 ps-lf-50 col-view v-center ft-sz-25 t-form-xy br-50 cl-white bg-blue z-i-100'>{!pageConst.isControlEnable ? (remainingSec > gamewaitingTime ? 'SHOW' : remainingSec) : remainingSec}</div>
                    <img className={`max-w-100 ${pageConst.showCircleAnim ? 'circle-trans' : 'circle-trans-none'}`} style={{transform: `rotate(${rotateValues}deg)`}} src={require(`../icons/CIRCLE/1.png`)} alt="icon" />
                  </div>
                </div>
                
               </div>

               <div className={`row-view mg-t-10 pd-15 ${pageConst.isControlEnable ? '' : 'disable-view'}`}>

               <div className="col-view a-center w-100 pd-10 cl-white br-10 bg-yellow bx-shdw-yellow" onClick={()=>updateBettingDialogState(null,true,'yellow','Yellow')}>
                  <span>YELLOW</span>
                  <span className='mg-t-5 cl-l-white'>1:2</span>
                </div>

                <div className="col-view a-center w-100 pd-10 cl-white br-10 bg-red bx-shdw-red mg-l-10" onClick={()=>updateBettingDialogState(null,true,'red','Red')}>
                  <span>RED</span>
                  <span className='mg-t-5 cl-l-white'>1:12</span>
                </div>

                <div className="col-view a-center w-100 pd-10 cl-white br-10 bg-green bx-shdw-green mg-l-10" onClick={()=>updateBettingDialogState(null,true,'green','Green')}>
                  <span>GREEN</span>
                  <span className='mg-t-5 cl-l-white'>1:2</span>
                </div>
                
               </div>
               
               <div className={`row-view pd-15 ${pageConst.isControlEnable ? '' : 'disable-view'}`}>

                <div className="col-view a-center w-100 pd-5 br-10 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'cow','Cow')}>
                  <img className='h-30-p' src={require(`../icons/CIRCLE/3.png`)} alt="icon" />
                  <span className='ft-sz-12 mg-t-5'>1:3</span>
                </div>

                <div className="col-view a-center w-100 pd-5 br-10 bg-white bx-shdw-blue mg-l-10" onClick={()=>updateBettingDialogState(null,true,'tiger','Tiger')}>
                  <img className='h-30-p' src={require(`../icons/CIRCLE/4.png`)} alt="icon" />
                  <span className='ft-sz-12 mg-t-5'>1:3</span>
                </div>

                <div className="col-view a-center w-100 pd-5 br-10 bg-white bx-shdw-blue mg-l-10" onClick={()=>updateBettingDialogState(null,true,'elephant','Elephant')}>
                  <img className='h-30-p' src={require(`../icons/CIRCLE/5.png`)} alt="icon" />
                  <span className='ft-sz-12 mg-t-5'>1:2</span>
                </div>

                <div className="col-view a-center w-100 pd-5 br-10 bg-white bx-shdw-blue mg-l-10" onClick={()=>updateBettingDialogState(null,true,'crown','Crown')}>
                  <img className='h-30-p' src={require(`../icons/CIRCLE/6.png`)} alt="icon" />
                  <span className='ft-sz-12 mg-t-5'>1:18</span>
                </div>
                
               </div>

               <div className='w-100 row-view sb-view cl-white pd-10-20'>
                <div onClick={() => updateActiveTabNumber('1-10')} className={`pd-5-10 ${pageConst.tabActiveNumber=='1-10' ? 'active-bx-view' : ''}`}>1~10</div>
                <div onClick={() => updateActiveTabNumber('11-20')} className={`pd-5-10 ${pageConst.tabActiveNumber=='11-20' ? 'active-bx-view' : ''}`}>11~20</div>
                <div onClick={() => updateActiveTabNumber('21-30')} className={`pd-5-10 ${pageConst.tabActiveNumber=='21-30' ? 'active-bx-view' : ''}`}>21~30</div>
                <div onClick={() => updateActiveTabNumber('31-0')} className={`pd-5-10 ${pageConst.tabActiveNumber=='31-0' ? 'active-bx-view' : ''}`}>31~36,0,00</div>
               </div>

               <div className={`g-v-5 pd-15 ${pageConst.isControlEnable ? '' : 'disable-view'} ${pageConst.tabActiveNumber!='1-10' ? 'hide-v' : ''}`}>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'1','1')}>1</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'2','2')}>2</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'3','3')}>3</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'4','4')}>4</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'5','5')}>5</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'6','6')}>6</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'7','7')}>7</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'8','8')}>8</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'9','9')}>9</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'10','10')}>10</div>

               </div>

               <div className={`g-v-5 pd-15 ${pageConst.isControlEnable ? '' : 'disable-view'} ${pageConst.tabActiveNumber!='11-20' ? 'hide-v' : ''}`}>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'11','11')}>11</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'12','12')}>12</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'13','13')}>13</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'14','14')}>14</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'15','15')}>15</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'16','16')}>16</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'17','17')}>17</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'18','18')}>18</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'19','19')}>19</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'20','20')}>20</div>

               </div>

               <div className={`g-v-5 pd-15 ${pageConst.isControlEnable ? '' : 'disable-view'} ${pageConst.tabActiveNumber!='21-30' ? 'hide-v' : ''}`}>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'21','21')}>21</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'22','22')}>22</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'23','23')}>23</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'24','24')}>24</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'25','25')}>25</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'26','26')}>26</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'27','27')}>27</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'28','28')}>28</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'28','29')}>29</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'30','30')}>30</div>

               </div>

               <div className={`g-v-5 pd-15 ${pageConst.isControlEnable ? '' : 'disable-view'} ${pageConst.tabActiveNumber!='31-0' ? 'hide-v' : ''}`}>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'31','31')}>31</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'32','32')}>32</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'33','33')}>33</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'34','34')}>34</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'35','35')}>35</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'36','36')}>36</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'0','0')}>0</div>

                <div className="v-center h-45-p w-100 pd-5 br-10 ft-sz-18 bg-white bx-shdw-blue" onClick={()=>updateBettingDialogState(null,true,'00','00')}>00</div>

               </div>

              </div>

              <div className="col-view br-right-t br-left-t mg-t-25 bg-white">

                <div className="tab-slct-v w-100">
                   <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActiveReord=="everyoneorder" ? 'active' : ''}`} onClick={()=>updateActiveTab('everyoneorder')}>Everyone's Order</div>
                   <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActiveReord=="myorder" ? 'active' : ''}`} onClick={()=>updateActiveTab('myorder')}>My Order</div>
                </div>

                <div className={`col-view min-h pd-18 ${pageConst.tabActiveReord!="everyoneorder" ? 'hide-v' : ''}`}>
                  <div className="row-view sb-view">
                    <span>Period</span>
                    <span>User</span>
                    <span>Select</span>
                    <span>Point</span>
                  </div>

                   <div className="col-view mg-t-15 fd-up-anim">
                    {pageConst.everyOnesOrderList.reverse()}
                   </div>
                </div>

                <div className={`col-view min-h pd-18 ${pageConst.tabActiveReord!="myorder" ? 'hide-v' : ''}`}>
                  <div className="row-view sb-view">
                    <span className='ft-sz-14'>Period</span>
                    <span className='ft-sz-14 mg-l-30'>Select</span>
                    <span className='ft-sz-14'>Point</span>
                    <span className='ft-sz-14'>Result</span>
                    <span className='ft-sz-14'>Amount</span>
                  </div>

                   <div className="col-view mg-t-15">
                    {pageConst.mygameRecordsList}
                   </div>

                   <Link className="w-100 v-center pd-10 mg-t-15 br-15 bg-grey txt-deco-n" to={"/myorders"}>More</Link>
                </div>

              </div>

           </div>
        </div>
      </div>
    );
}

export default Wheelocity;