import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import BettingDialog from '../dialogs/BettingDialog';
import LoadingDialog from '../dialogs/LoadingDialog';
import ToastDialog from '../dialogs/ToastDialog';
import WinDialog from '../dialogs/WinDialog';
import RuleDialog from '../dialogs/SpareRuleDialog';
import { setStorage, getStorage } from '../modals/Storage';
import { API_ACCESS_URL } from '../modals/Constants';
import '../../MainStyle.css';

function FirstParity(){

    const [remainingSec, setCount] = useState(60);
    const [disableTime, setDisable] = useState(30);

    const [pageConst, setGameData] = useState({
        pageTitle: "Spare",
        gamePeriodId: "",
        gameCode: "SPARE",
        remainingSec1: 0,
        remainingSec2: 0,
        remainingMin1: 0,
        remainingMin2: 0,
        isControlEnable: true,
        requestAccountData: true,
        isLoadingShow: false,
        ruleDialogShow: false,
        winDialogShow: false,
        toastDialogShow: false,
        toastMessage: "",
        tabActiveReord: "everyoneorder",
        gameWinDetails: [],
        gameRecordsList: [],
        mygameRecordsList: [],
        everyOnesOrderList: [],
    });

    const [bettingDialogConst, setBettingDialogConst] = useState({
      bettingDialogShow: false,
      bettingDialogTitle: "Join Green",
      bettingDialogTheme: "dlg-thm-green",
      gameBetRoutePath: "route-bet-common",
      gameSelectedBet: "",
    });

    const [winDialogConst, setWinDialogConst] = useState({
      winDialogShow: false,
      constPeriodId: "123",
      constWinAmount: 0,
    });

    const handleVisibilityChange = useCallback(() => {
      let isVisible = document.visibilityState === 'visible';
      if(isVisible){
        getGameData();
      }
    }, []);

    const updatePeriod = (data) => {
        setGameData(previousState => {
          return { ...previousState, gamePeriodId: data[0].game_period_id }
        });
    }
    
    const updateWinDialogState = (data) => {
      setWinDialogConst(previousState => {
        return { ...previousState, winDialogShow: data }
      });
    }

    const updateGameControl = (data) => {
        setGameData(previousState => {
            return { ...previousState, isControlEnable: data }
        });
    }

    const updateReqAcntData = (data) => {
        if(data=="false"){
            setGameData(previousState => {
                return { ...previousState, requestAccountData: false }
            });
        }else{
            setGameData(previousState => {
                return { ...previousState, requestAccountData: true }
            });
        }
    }

    const updateRemainSec = (data,val) => {
        if(data=="1"){
            setGameData(previousState => {
                return { ...previousState, remainingSec1: val }
            });
        }else{
            setGameData(previousState => {
                return { ...previousState, remainingSec2: val }
            });
        }
    }

    const updateRemainMin = (data,val) => {
        if(data=="1"){
            setGameData(previousState => {
                return { ...previousState, remainingMin1: val }
            });
        }else{
            setGameData(previousState => {
                return { ...previousState, remainingMin2: val }
            });
        }
    }

    const updateActiveTab = (data) => {
        setGameData(previousState => {
            return { ...previousState, tabActiveReord: data }
        });
    }

    const updateLoadingStatus = (data) => {
      setGameData(previousState => {
          return { ...previousState, isLoadingShow: data }
      });
    }

    const getDialogThemeClassName = (data) =>{
      let returnVal = "dlg-thm-green";
      if(data=="r" || data=="rv"){
        returnVal = "dlg-thm-red";
      }else if(data=="v"){
        returnVal = "dlg-thm-violet";
      }

      return returnVal;
    }
    
    const updateBettingDialogState = (e,data,selected,title) => {

      if(selected!=""){
        setBettingDialogConst(previousState => {
          return { ...previousState, gameSelectedBet: selected }
        });
      }

      if(e!=null && e.target.className=="ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog"){
        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogShow: data }
        });
      }else if(data==true && pageConst.isControlEnable){
        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogShow: data }
        });

        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogTitle: title }
        });

        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogTheme: getDialogThemeClassName(getColourCodeVal(selected)) }
        });
        
      }else if(data=="dismiss"){
        setBettingDialogConst(previousState => {
          return { ...previousState, bettingDialogShow: false }
        });
      }
    }

    const topBarClickAction = (data) =>{
      if(data=="multiBtn1"){

      }else{
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: true }
        });
      }
    }

    const updateGameRuleDialog = (e,data) => {
      if(e!=null && e.target.className=="ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg activeDialog"){
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: data }
        });
      }else if(data=="dismiss"){
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: false }
        });
      }else if(data=="true"){
        setGameData(previousState => {
          return { ...previousState, ruleDialogShow: true }
        });
      }
    } 

    const updateToastDialogState = (data,msg) => {

      setGameData(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setGameData(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const getCapitalLetter = (data) => {
       let returnVal = "";

       if(data=="red"){
         returnVal = "R";
       }else if(data=="green"){
         returnVal = "G";
       }else if(data=="violet"){
         returnVal = "V";
       }else{
         returnVal = data;
       }

       return returnVal;  
    }

    const getColourCodeVal = (data) => {
        let returnVal = "";

        if(data=="1" || data=="3" || data=="7" || data=="9"){
            returnVal = "g";
        }else if(data=="2" || data=="4" || data=="6" || data=="8"){
            returnVal = "r";
        }else if(data=="0"){
            returnVal = "rv";
        }else if(data=="5"){
            returnVal = "gv";
        }else if(data=="red"){
          returnVal = "r";
        }else if(data=="green"){
          returnVal = "g";
        }else if(data=="violet"){
          returnVal = "v";
        }

        return returnVal;
    }

    const updateGameRecords = (data) => {
        let tempData = [],currentPeriod="",numNeedToRemove="";
        for (let i = data.length-1; i > -1; i--) {
          if(i!=29){
            
            if(i==0){
              currentPeriod = Number(data[i]['period_id'].slice(-3))+1;
              if(currentPeriod < 10){
                currentPeriod = "00"+currentPeriod;
              }else if(currentPeriod < 100){
                currentPeriod = "0"+currentPeriod;
              }
              numNeedToRemove = 10-Number(data[i]['period_id'].slice(-1));
            }
  
            tempData.push(
              <div key={i} className="col-view a-center">
                <div className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getColourCodeVal(data[i]['match_result'])=="r" ? 'bg-red' : getColourCodeVal(data[i]['match_result'])=="g" ? 'bg-green' : getColourCodeVal(data[i]['match_result'])=="rv" ? 'bg-rv' : 'bg-gv'}`}>{data[i]['match_result']}</div>
                <span className="ft-sz-14">{data[i]['period_id'].slice(-3)}</span>
              </div>)
          } 
        };
      

        if(data.length > 20){
          tempData.splice(0, numNeedToRemove);
        }

        tempData.push(
          <div key={31} className="col-view a-center">
            <div className='ft-sz-17 h-w-28 br-50 cl-white v-center bg-yellow'>?</div>
            <span className="ft-sz-14">{currentPeriod}</span>
          </div>)

        setGameData(previousState => {
          return { ...previousState, gameRecordsList: tempData }
        });

    }

    const updateEveryOnesOrder = (data) => {
      setGameData(previousState => {
        return { ...previousState, everyOnesOrderList: data }
      });
    }

    const getFixedDecimalVal = (data) => {
      return Number(data).toFixed();
    }

    const updateMyGameRecords = (data) => {
      let tempData = [];

        for (let i = 0; i < data.length; i++) {

          if(i==0 && getStorage("recentsparebet")==data[i]['m_period_id']){
            console.log(data[i]);

            if(data[0]['m_status']=="profit"){
              setWinDialogConst(previousState => {
                return { ...previousState, constPeriodId: data[0]['m_period_id'] }
              });
  
              setWinDialogConst(previousState => {
                return { ...previousState, constWinAmount: data[0]['m_profit'] }
              });

              setStorage("recentsparebet", "");
              updateWinDialogState(true);
            }
          }

          let tempMatchReslt = "";
          if(data[i]['m_status']=="profit"){
            if(Number(data[i]['m_profit']) >= 1000000){
              tempMatchReslt = "+₹"+getFixedDecimalVal(Number(data[i]['m_profit'])/1000)+"K";
            }else if(Number(data[i]['m_profit']) >= 100000){
              tempMatchReslt = "+₹"+getFixedDecimalVal(Number(data[i]['m_profit'])/1000)+"K";
            }else if(Number(data[i]['m_profit']) >= 10000){
              tempMatchReslt = "+₹"+getFixedDecimalVal(Number(data[i]['m_profit'])/1000)+"K";
            }else{
              tempMatchReslt = "+₹"+Number(data[i]['m_profit']);
            }
          }else if(data[i]['m_status']=="loss"){
            if(Number(data[i]['m_cost']) >= 1000000){
              tempMatchReslt = "-₹"+getFixedDecimalVal(Number(data[i]['m_cost'])/1000)+"K";
            }else if(Number(data[i]['m_cost']) >= 100000){
              tempMatchReslt = "-₹"+getFixedDecimalVal(Number(data[i]['m_cost'])/1000)+"K";
            }else if(Number(data[i]['m_cost']) >= 10000){
              tempMatchReslt = "-₹"+getFixedDecimalVal(Number(data[i]['m_cost'])/1000)+"K";
            }else{
              tempMatchReslt = "-₹"+Number(data[i]['m_cost']);
            }
          }
          
          tempData.push(
            <div key={i} className="row-view mg-t-15 sb-view">
              <span className='ft-sz-14'>{data[i].m_period_id}</span>
              <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getColourCodeVal(data[i]['m_color'])=="r" ? 'bg-red' : getColourCodeVal(data[i]['m_color'])=="g" ? 'bg-green' : getColourCodeVal(data[i]['m_color'])=="rv" ? 'bg-rv' : getColourCodeVal(data[i]['m_color'])=="gv" ? 'bg-gv' : 'bg-violet'}`}>{getCapitalLetter(data[i]['m_color'])}</span>
              <span className='ft-sz-15'>{data[i]['m_cost']+".00"}</span>
              <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getColourCodeVal(data[i]['m_result'])=="r" ? 'bg-red' : getColourCodeVal(data[i]['m_result'])=="g" ? 'bg-green' : getColourCodeVal(data[i]['m_result'])=="rv" ? 'bg-rv' : getColourCodeVal(data[i]['m_result'])=="gv" ? 'bg-gv' : ''} `}>{getCapitalLetter(data[i]['m_result'])}</span>
              <span className={`w-65-p ft-sz-15 txt-a-end ${data[i]['m_status']=="loss" ? 'cl-red' : 'cl-green'}`} >{tempMatchReslt}</span>
            </div>)
        };

        setGameData(previousState => {
            return { ...previousState, mygameRecordsList: tempData }
        });
    }

    const getRandBool = () => {
      let returnVal = false;

      let status=Math.round(Math.random())
      if(status==1){
        returnVal = true;
      }

      return returnVal;
    }

    const getRandomNum = (min, max, type) => {
      if(type=="multi"){
        return Math.round((Math.random()*(max-min)+min)/10)*10;
      }else{
        return Math.floor(Math.random() * (max - min) + min);
      }
    }

    const recentBetDetails = (invested_amount) =>{
      setStorage("recentsparebet", pageConst.gamePeriodId);

      let tempData = [];

      tempData.push(
        <div key={60} className="row-view mg-t-15 sb-view">
          <span className='ft-sz-14'>{pageConst.gamePeriodId}</span>
          <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getColourCodeVal(bettingDialogConst.gameSelectedBet)=="r" ? 'bg-red' : getColourCodeVal(bettingDialogConst.gameSelectedBet)=="g" ? 'bg-green' : getColourCodeVal(bettingDialogConst.gameSelectedBet)=="rv" ? 'bg-rv' : getColourCodeVal(bettingDialogConst.gameSelectedBet)=="gv" ? 'bg-gv' : 'bg-violet'}`}>{getCapitalLetter(bettingDialogConst.gameSelectedBet)}</span>
          <span className='ft-sz-15'>{invested_amount}.00</span>
          <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center`}></span>
          <span className={`w-65-p ft-sz-15 txt-a-end`} ></span>
        </div>)
        
        let finalArr = [tempData].concat(pageConst.mygameRecordsList);
        
        setGameData(previousState => {
          return { ...previousState, mygameRecordsList: finalArr }
        });
    }

    const updateEveryOnesOrders = (periodId,remainSec,controlEnabled,everyoneorder) => {
      
      if(periodId!=""){
        let tempData = [];

        if(everyoneorder.length > 0){
          tempData = everyoneorder;
        }
  
        if(controlEnabled==true && getRandBool()){
  
          let randBetOn = getRandBool();
          let randBetId = getRandomNum(100,999, '');
          let randBetAmnt = 10;
          if(getRandBool()){
            randBetAmnt = getRandomNum(10,300, 'multi');
          }else{
            randBetAmnt = getRandomNum(10,2000, 'multi');
          }

          let randBetNum = "";

          if(getRandBool()){
            if(getRandBool()){
              randBetNum = "red"
            }else{
              randBetNum = "green";
            }
          }else{
            randBetNum = getRandomNum(0,9, '');
          }
  
          tempData.push(
            <div key={remainSec} className="row-view mg-t-15 sb-view">
              <span className='ft-sz-14'>{periodId}</span>
              <span className='ft-sz-14'>***{randBetId}</span>
              <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getColourCodeVal(randBetNum)=="r" ? 'bg-red' : getColourCodeVal(randBetNum)=="g" ? 'bg-green' : getColourCodeVal(randBetNum)=="rv" ? 'bg-rv' : 'bg-gv'}`}>{getCapitalLetter(randBetNum)}</span>
              <span className="ft-sz-14 w-50-p">₹{randBetAmnt}</span>
            </div>)

          updateEveryOnesOrder(tempData.reverse());
        }
        
      }
    }

    function getGameData(){
      updateLoadingStatus(true);

      const fecthApiData = async (url) => {
        
        try {
          const res = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Route': 'route-setup-common',
              'AuthToken': getStorage("secret"),
            }
          });

          const data = await res.json();

          updateLoadingStatus(false);

          if(data.status_code=="success"){
            updatePeriod(data.gamedata);
            setCount(data.gamedata[0].game_remain_seconds);
            setDisable(data.gamedata[0].game_disable_time);
            updateGameRecords(data.matchrecords);
            updateMyGameRecords(data.mymatchrecords);
          }else{
            updateToastDialogState(true, "Something went wrong! Please try again or login!");
          }

        } catch (error) {
          updateLoadingStatus(false);
          console.log(error);
        }
      };

      fecthApiData(API_ACCESS_URL+"?USER_ID="+getStorage("uid")+"&PROJECT_NAME="+pageConst.gameCode);
    }

    function setUpTimer(data){
        if(data=="true"){
            updateReqAcntData("true");
        }

        if(pageConst.requestAccountData==true){
            updateReqAcntData("false");
            getGameData();
        }
    }

    useEffect(() => {

        document.addEventListener("visibilitychange", handleVisibilityChange);
        setUpTimer("false");

        const interval = setInterval(() => {
            if(remainingSec >= 0){
                setCount(remainingSec - 1);

                if(remainingSec <= 0){
                    setUpTimer("true");
                    setCount(60);
                    updateEveryOnesOrder([]);
                }else{

                    function splitIntoArray(num) {
                      return Array.from(String(num), Number);
                    }

                    const minutes = Math.floor(remainingSec / 60);
                    const seconds = remainingSec % 60;
                
                    if (minutes < 10) {
                      updateRemainMin("1", 0);
                      updateRemainMin("2", minutes);
                    } else {
                      var numArr = splitIntoArray(minutes);
                      updateRemainMin("1", numArr[0]);
                      updateRemainMin("2", numArr[1]);
                    }
                
                    if (seconds < 10) {
                      updateRemainSec("1", 0);
                      updateRemainSec("2", seconds);
                    } else {
                      var numArr = splitIntoArray(seconds);
                      updateRemainSec("1", numArr[0]);
                      updateRemainSec("2", numArr[1]);
                    }

                    if(remainingSec < disableTime && pageConst.isControlEnable == true){
                      updateGameControl(false);
                    }else if (remainingSec > disableTime && pageConst.isControlEnable == false) {
                      updateGameControl(true);
                    }

                    updateEveryOnesOrders(pageConst.gamePeriodId,remainingSec,pageConst.isControlEnable,pageConst.everyOnesOrderList);
                }
            }
        }, 1000);

        //Clearing the interval
        return () => {
          clearInterval(interval);
          document.removeEventListener("visibilitychange", handleVisibilityChange);
        }

    }, [remainingSec]);


    return (
      <div className="v-center">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-grey">
           <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="Rule" updateState={topBarClickAction}/>
           <LoadingDialog intentData={pageConst}/>
           <WinDialog intentData={winDialogConst} updateState={updateWinDialogState}/>
           <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
           <RuleDialog intentData={pageConst} updateState={updateGameRuleDialog}/>
           <BettingDialog gameCode={pageConst.gameCode} dialogData={bettingDialogConst} updateState={updateBettingDialogState} toastUpdate={updateToastDialogState} recentBetDetails={recentBetDetails} />

           <div className="game-manage-view col-view mg-t-45">

              <div className="col-view bg-white">

               <div className="row-view sb-view pd-15">
                <div className="col-view">
                    <span className="m-bottom-n">Period</span>
                    <span className="f-size-max t-color-blk ltr-s ft-wgt-600">{pageConst.gamePeriodId}</span>
                </div>

                <div className="col-view a-right">
                    <span className="m-bottom-n">Count Down</span>
                    <div className="t-countview">
                        <span>{pageConst.remainingMin1}</span>
                        <span>{pageConst.remainingMin2}</span>
                        :
                        <span>{pageConst.remainingSec1}</span>
                        <span>{pageConst.remainingSec2}</span>
                    </div>
                </div>
               </div>

               <div className={`row-view mg-t-10 pd-15  ${pageConst.isControlEnable ? '' : 'disable-view'}`}>

                <div className="col-view w-100 a-center" onClick={()=>updateBettingDialogState(null,true,'green','Join Green')}>
                  <div className="col-view w-100 a-center pd-10 cl-white br-5 dsbl-bg bg-green bx-shdw-green">
                    <img className='h-30-p' src={require('../icons/rocket_icon.png')} alt="icon" />
                    <span>Join Green</span>
                  </div>
                  <span className='mg-t-5'>1:2</span>
                </div>

                <div className="col-view w-100 a-center mg-l-10" onClick={()=>updateBettingDialogState(null,true,'violet','Join Violet')}>
                  <div className="col-view w-100 a-center pd-10 cl-white br-5 dsbl-bg bg-violet bx-shdw-violet">
                    <img className='h-30-p' src={require('../icons/rocket_icon.png')} alt="icon" />
                    <span>Join Violet</span>
                  </div>
                  <span className='mg-t-5'>1:5</span>
                </div>

                <div className="col-view w-100 a-center mg-l-10" onClick={()=>updateBettingDialogState(null,true,'red','Join Red')}>
                  <div className="col-view w-100 a-center pd-10 cl-white br-5 dsbl-bg bg-red bx-shdw-red">
                    <img className='h-30-p' src={require('../icons/rocket_icon.png')} alt="icon" />
                    <span>Join Red</span>
                  </div>
                  <span className='mg-t-5'>1:2</span>
                </div>
                
               </div>

               <div className={`g-v-5 ft-sz-20 pd-15 ${pageConst.isControlEnable ? '' : 'disable-view'}`}>
                <div className="dsbl-bg br-5 smpl-btn s-bet-btn" onClick={()=>updateBettingDialogState(null,true,'1','Select 1')}>1</div>
                <div className="dsbl-bg br-5 smpl-btn s-bet-btn" onClick={()=>updateBettingDialogState(null,true,'2','Select 2')}>2</div>
                <div className="dsbl-bg br-5 smpl-btn s-bet-btn" onClick={()=>updateBettingDialogState(null,true,'3','Select 3')}>3</div>
                <div className="dsbl-bg br-5 smpl-btn s-bet-btn" onClick={()=>updateBettingDialogState(null,true,'4','Select 4')}>4</div>
                <div className="dsbl-bg br-5 smpl-btn s-bet-btn" onClick={()=>updateBettingDialogState(null,true,'5','Select 5')}>5</div>
                <div className="dsbl-bg br-5 smpl-btn s-bet-btn" onClick={()=>updateBettingDialogState(null,true,'6','Select 6')}>6</div>
                <div className="dsbl-bg br-5 smpl-btn s-bet-btn" onClick={()=>updateBettingDialogState(null,true,'7','Select 7')}>7</div>
                <div className="dsbl-bg br-5 smpl-btn s-bet-btn" onClick={()=>updateBettingDialogState(null,true,'8','Select 8')}>8</div>
                <div className="dsbl-bg br-5 smpl-btn s-bet-btn" onClick={()=>updateBettingDialogState(null,true,'9','Select 9')}>9</div>
                <div className="dsbl-bg br-5 smpl-btn s-bet-btn" onClick={()=>updateBettingDialogState(null,true,'0','Select 0')}>0</div>
               </div>

              </div>

              <div className="col-view br-right-t br-left-t mg-t-25 bg-white">

                <div className="tab-slct-v w-100">
                  <div className="v-center tab-in-v pd-10 ft-sz-18 ft-wgt-700 w-100 active">Record</div>
                </div>

                <div className="col-view pd-10">
                  <div className="row-view sb-view">
                    <span>Parity Records</span>
                    <Link className="rc-btn txt-deco-n" to={"/gamerecords?GC="+pageConst.gameCode}>more</Link>
                  </div>

                   <div className="g-v-10 mg-t-15">
                    {pageConst.gameRecordsList}
                   </div>
                </div>

              </div>

              <div className="col-view br-right-t br-left-t mg-t-25 mg-b-15 bg-white">

                <div className="tab-slct-v w-100">
                   <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActiveReord=="everyoneorder" ? 'active' : ''}`} onClick={()=>updateActiveTab('everyoneorder')}>Everyone's Order</div>
                   <div className={`v-center tab-in-v pd-10 ft-sz-18 w-100 ${pageConst.tabActiveReord=="myorder" ? 'active' : ''}`} onClick={()=>updateActiveTab('myorder')}>My Order</div>
                </div>

                <div className={`col-view min-h pd-18 ${pageConst.tabActiveReord!="everyoneorder" ? 'hide-v' : ''}`}>
                  <div className="row-view sb-view">
                    <span>Period</span>
                    <span>User</span>
                    <span>Select</span>
                    <span>Point</span>
                  </div>

                   <div className="col-view mg-t-15 fd-up-anim">
                    {pageConst.everyOnesOrderList}
                   </div>
                </div>

                <div className={`col-view min-h pd-18 ${pageConst.tabActiveReord!="myorder" ? 'hide-v' : ''}`}>
                  <div className="row-view sb-view">
                    <span className='ft-sz-14'>Period</span>
                    <span className='ft-sz-14 mg-l-30'>Select</span>
                    <span className='ft-sz-14'>Point</span>
                    <span className='ft-sz-14'>Result</span>
                    <span className='ft-sz-14'>Amount</span>
                  </div>

                   <div className="col-view mg-t-15">
                    {pageConst.mygameRecordsList}
                   </div>

                   <Link className="w-100 v-center pd-10 mg-t-15 br-15 bg-grey txt-deco-n" to={"/myorders"}>More</Link>
                </div>

              </div>

           </div>
        </div>
      </div>
    );
}

export default FirstParity;