import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import LoadingDialog from '../dialogs/LoadingDialog';
import '../../MainStyle.css';
import { API_ACCESS_URL } from '../modals/Constants';
import { getStorage } from '../modals/Storage';

function GameRecords(){
    const navigate = useNavigate();
    const [pageConst, setConstants] = useState({
        pageTitle: "My Orders",
        isLoadingShow: false,
        tabActive: "FAST_PARITY",
        recordList: [],
    });

    const topBarClickAction = (data) =>{
    }

    const updateTabActive = (data) => {
        getGameRecords(data);

        setConstants(previousState => {
           return { ...previousState, tabActive: data }
        });
    }

    const updateLoadingStatus = (data) => {
      setConstants(previousState => {
          return { ...previousState, isLoadingShow: data }
      });
    }

    const getColourCodeVal = (data) => {
        let returnVal = "";

        if(data=="1" || data=="3" || data=="7" || data=="9"){
            returnVal = "g";
        }else if(data=="2" || data=="4" || data=="6" || data=="8"){
            returnVal = "r";
        }else if(data=="0"){
            returnVal = "rv";
        }else if(data=="5"){
            returnVal = "gv";
        }else if(data=="red"){
          returnVal = "r";
        }else if(data=="green"){
          returnVal = "g";
        }else if(data=="violet"){
          returnVal = "v";
        }

        return returnVal;
    }

    const updateGameRecords = (data) => {
      let tempData = [];

        for (let i = 0; i < data.length; i++) {      
            tempData.push(
              <div key={i} className='pr-v w-100 br-5 mg-t-15 pd-10 bg-extm-l-white br-a-l-blue'>
                <div className='row-view sb-view ft-sz-15'>
                  <span>{data[i]['r_match_id']}</span>
                  <span>{data[i]['r_date']+" "+data[i]['r_time']}</span>
                </div>

                <div className='row-view sb-view w-100 ft-sz-14 mg-t-20'>
                  <div className='row-view'>
                    <div className='col-view v-center'>
                      <span className='ft-sz-15 mg-t-5'>Selected</span>
                      <span className='ft-sz-15 mg-t-5'>Red</span>
                    </div>

                    <div className='col-view v-center mg-l-30'>
                      <span className='ft-sz-15 mg-t-5'>Point</span>
                      <span className='ft-sz-15 mg-t-5'>{data[i]['r_match_amount']}</span>
                    </div>

                    <div className='col-view v-center mg-l-30'>
                      <span className='ft-sz-15 mg-t-5'>Result</span>
                      <span className='ft-sz-15 mg-t-5'>{data[i]['r_match_result']}</span>
                    </div>
                  </div>


                  <div className={`pd-2-8 ft-sz-25 ${data[i]['r_match_status']=="success" ? 'cl-green' : (data[i]['r_match_status']=="rejected" ? 'cl-red' : '')}`}>₹{data[i]['r_match_amount']}</div>
                </div>
            </div>)
        };

        updateLoadingStatus(false);

        setConstants(previousState => {
            return { ...previousState, recordList: tempData }
        });
    }

    function getGameRecords(project){
      updateLoadingStatus(true);

        const fecthApiData = async (url) => {
          try {
            const res = await fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Route': 'route-mygame-records',
                'AuthToken': getStorage("secret"),
              }
            });
  
            const data = await res.json();
            updateLoadingStatus(false);
            updateGameRecords(data.data);

          } catch (error) {
            updateLoadingStatus(false);
            console.log(error);
          }
        };

        fecthApiData(API_ACCESS_URL+"?USER_ID="+getStorage("uid")+"&PROJECT="+project);
    }
  
    useEffect(() => {
        getGameRecords(pageConst.tabActive);
    }, []);

    return (
      <div className="v-center">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-white">
          <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>
          <LoadingDialog intentData={pageConst}/>

          <div className="game-manage-view col-view mg-t-45">

            <div className="col-view br-right-t br-left-t mg-b-15 bg-white">

              <div className='col-view min-h'>
                <div className="row-view ovf-scrl-x bg-drk-blue cl-l-grey hide-sb pd-0-10">
                  <span className={`pd-10 ${pageConst.tabActive == "FAST_PARITY" ? 'tab-h-active' : ''}`} onClick={() => updateTabActive("FAST_PARITY")}>FastParity</span>
                  <span className={`pd-10 mg-l-20 ${pageConst.tabActive == "PARITY" ? 'tab-h-active' : ''}`} onClick={() => updateTabActive("PARITY")}>Parity</span>
                  <span className={`pd-10 mg-l-20 ${pageConst.tabActive == "DICE" ? 'tab-h-active' : ''}`} onClick={() => updateTabActive("DICE")}>Dice</span>
                  <span className={`pd-10 mg-l-20 ${pageConst.tabActive == "ANDAR_BAHAR" ? 'tab-h-active' : ''}`} onClick={() => updateTabActive("ANDAR_BAHAR")}>AndarBahar</span>
                  <span className={`pd-10 mg-l-20 ${pageConst.tabActive == "AVIATOR" ? 'tab-h-active' : ''}`} onClick={() => updateTabActive("AVIATOR")}>Aviator</span>
                  <span className={`pd-10 mg-l-20 ${pageConst.tabActive == "WHEELOCITY" ? 'tab-h-active' : ''}`} onClick={() => updateTabActive("WHEELOCITY")}>Roulette</span>
                </div>

                <div className='col-view pd-18'>
                  <div className="col-view">
                  {pageConst.recordList}
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    );
}

export default GameRecords;